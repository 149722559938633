
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import moment from "moment";

import DatePicker from "@/app/modules/_global/components/elements/DatePicker.vue";
import TimePicker from "@/app/modules/_global/components/elements/TimePicker.vue";

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    datepicker_: DatePicker,
    timepicker_: TimePicker
  },

  filters: {
    parse_weekday(value: number) {
      return moment.weekdaysMin(value);
    }
  }
})
export default class BlockFormComponent extends Vue {
  @VModel({ type: Object }) formData!: object;
  @Prop(Boolean) readonly readonly?: boolean;
  @Prop(Object) readonly errors?: object;

  valid = true;
  reasons = [];
  reiterations = [];
  weekdays = [1, 2, 3, 4, 5, 6, 0];
  dateMin = moment().format("YYYY-MM-DD");

  rules = {
    required: Rules.required,
    date: Rules.date
  };

  created() {
    this.$store.dispatch("schedule/getReasonsList").then(response => {
      this.reasons = response.data.data;
    });

    this.$store.dispatch("schedule/getReiterationsList").then(response => {
      this.reiterations = response.data.data;
    });
  }

  get isBeforeToday() {
    return moment(this.formData["date_from"]).isBefore(moment().startOf("day"));
  }

  isMultiTaskChanged(value: boolean) {
    this.formData["end_date"] = value ? this.formData["start_date"] : null;
  }
}
